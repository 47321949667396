<template>
    <AdminWrapper>
        <template v-slot:child>
            <div>
                <div class="flex flex-wrap items-center justify-between items-center pv4 w-90 center">
                    <div>
                        <router-link
                            :to="{ name: 'AdminAccountDetails', params: { id: $route.params.id } }"
                            class="pv4-l pv3 mini-spacing"
                        >
                            &lt; Account Details
                        </router-link>
                    </div>
                    <div>
                        <div class="pb3 pb0-l f5 b lh-copy">Edit Business Profile</div>
                    </div>
                    <!-- <div>
                        <button class="btn3 mr3">Create users</button>
                        <button class="btn3">Deactive</button>
                    </div> -->
                </div>

                <form @submit.prevent="updateUserInfo()" class="w-90 center flex-l items-start justify-between">
                    <div class="box-border pa3 w-50-l w-100 mb4 mb0-l">
                        <div class="flex flex-column">
                            <div class="b pt4 pb2">Name</div>
                            <input
                                v-model="orgName"
                                type="text"
                                class="pa2 box-border"
                                name=""
                                placeholder="Donye Collins"
                                id=""
                            />
                        </div>
                        <!-- <div class="flex flex-column">
                            <div class="b pt3 pb2">Last Name</div>
                            <input type="text" class="pa2 box-border" name="" placeholder="Donye Collins" id="" />
                        </div> -->
                        <div class="flex flex-column">
                            <div class="b pt3 pb2">Organization Email</div>
                            <input
                                v-model="orgEmail"
                                type="email"
                                class="pa2 box-border"
                                name=""
                                placeholder="Donye@hot.co"
                                id=""
                            />
                        </div>
                        <div class="flex flex-column">
                            <div class="b pt3 pb2">Phone No</div>
                            <input
                                v-model="orgPhoneNo"
                                type="number"
                                class="pa2 box-border"
                                name=""
                                placeholder="07055896214"
                                id=""
                            />
                        </div>
                        <div class="flex flex-column">
                            <div class="b pt3 pb2">Primary Owner Name</div>
                            <input v-model="primaryOwnerName" type="text" class="pa2 box-border" />
                        </div>
                        <div class="flex flex-column">
                            <div class="b pt3 pb2">Primary Owner Email</div>
                            <input v-model="primaryOwnerEmail" type="email" class="pa2 box-border" />
                        </div>
                        <div class="flex flex-column">
                            <div class="b pt3 pb2">Address</div>
                            <input v-model="orgAddress" type="text" class="pa2 box-border" />
                        </div>
                        <div class="flex flex-column">
                            <div class="b pt3 pb2">Country</div>
                            <!-- <input v-model="orgCountry" type="text" class="pa2 box-border" /> -->
                            <select v-model="orgCountry" name="country" class="w-100" id="country">
                                <option value="" selected>Choose Country</option>
                                <option v-for="country in sortedCountries" :key="country.alpha2Code" :value="country.name.common">
                                    {{ country.name.common }}
                                </option>
                            </select>
                        </div>
                        <div class="flex flex-column">
                            <div class="b pt3 pb2">Source</div>
                            <input v-model="orgSource" type="text" class="pa2 box-border" />
                        </div>
                        <div class="flex flex-column">
                            <div class="b pt3 pb2">Type of Business</div>
                            <select v-model="orgType" type="text" class="pa2 box-border" name="" placeholder="Admin" id="">
                                <option value="Accommodation and Food Services">Accommodation and Food Services</option>
                                <option value="Administrative and Support Services">Administrative and Support Services</option>
                                <option value="Arts and Recreation Services">Arts and Recreation Services</option>
                                <option value="Construction/Builder">Construction/Builder</option>
                                <option value="Creative Arts & Media Production">Creative Arts & Media Production</option>
                                <option value="Education and Training">Education and Training</option>
                                <option value="Farming, forestry and fishing">Farming, forestry and fishing</option>
                                <option value="Financial services & insurance">Financial services & insurance</option>
                                <option value="Manufacturing">Manufacturing</option>
                                <option value="Medical / Health Care / Community services">
                                    Medical / Health Care / Community services
                                </option>
                                <option value="Personal, Beauty, Wellbeing and other services">
                                    Personal, Beauty, Wellbeing and other services
                                </option>
                                <option value="Professional Services (e.g. Legal, Accounting, Marketing, Consulting)">
                                    Professional Services (e.g. Legal, Accounting, Marketing, Consulting)
                                </option>
                                <option value="Property Operators and Real Estate services">
                                    Property Operators and Real Estate services
                                </option>
                                <option value="Rental & Hiring services (non Real Estate)">
                                    Rental & Hiring services (non Real Estate)
                                </option>
                                <option value="Repair and Maintenance (Automotive & Property)">
                                    Repair and Maintenance (Automotive & Property)
                                </option>
                                <option value="Retail Trade (Food & Beverage)">Retail Trade (Food & Beverage)</option>
                                <option value="Retail Trade & Ecommerce (Non-Food)">Retail Trade & Ecommerce (Non-Food)</option>
                                <option value="Technology / Telecommunications services">
                                    Technology / Telecommunications services
                                </option>
                                <option value="Trades work (e.g. Plumber, Carpenter, Electrician)">
                                    Trades work (e.g. Plumber, Carpenter, Electrician)
                                </option>
                                <option value="Transport, Logistics, Postal, Warehousing">
                                    Transport, Logistics, Postal, Warehousing
                                </option>
                                <option value="Wholesale Trade">Wholesale Trade</option>
                                <option value="Other">Other</option>
                            </select>
                        </div>

                        <div class="flex flex-column">
                            <div class="b pt3 pb2">Sector</div>
                            <select v-model="orgSector" type="text" class="pa2 box-border" name="" placeholder="Admin" id="">
                                <option value="">Select an option</option>
                                <option value="Sole Trader/Business Name">Sole Trader/Business Name</option>
                                <option value="Partnership">Partnership</option>
                                <option value="Limited Liability Company">Limited Liability Company</option>
                                <option value="Non Profit">Non Profit</option>
                            </select>
                        </div>
                        <!-- <div class="flex flex-column">
                            <div class="b pt3 pb2">Role</div>
                            <input type="text" class="pa2 box-border" name="" placeholder="Admin" id="" />
                        </div> -->
                        <!-- <div class="flex flex-column pb4">
                            <div class="b pt3 pb2">Role description</div>
                            <input
                                type="text"
                                class="pa2 box-border"
                                name=""
                                placeholder="Importing and exporting of  staff to uk for faji"
                                id=""
                            />
                        </div> -->
                        <div class="tr pv2">
                            <button class="btn1">Update Business</button>
                        </div>
                    </div>
                    <div class="box-border pa3 w-50-l w-100 ml4-l">
                        <div class="box-border-bottom">
                            <h3>Profile photo</h3>
                        </div>

                        <div class="flex pv4">
                            <img :src="require('@/assets/images/profile-pic.svg')" alt="" />
                            <div class="pl3">
                                <div class="font-w2 pb2">Edit your photo</div>
                                <div class="flex">
                                    <a href="">Delete</a>
                                    <a href="" class="ml3 font-w2" style="color: #132c8c">Update</a>
                                </div>
                            </div>
                        </div>
                        <label for="file-upload" class="custom-file-upload w-100 bw1 dashedinputFile" style="text-align: center">
                            <img :src="require('@/assets/images/images.svg')" class="pb3 pt4" alt="" />
                            <div class="font-w2 pb2">
                                Drag and drop an image, or <span href="" style="color: #132c8c">Browse</span>
                            </div>
                            <div class="font-w1 pb3">386 * 250 PX or higher recommended, Max 3MB (webp, png, jpg)</div>
                            <input id="file-upload" type="file" accept=".webp, .png, .jpg, .jpeg" style="visibility: hidden" />
                        </label>
                    </div>
                </form>
            </div>
        </template>
    </AdminWrapper>
</template>

<script>
import Spinner from '@/components/Spinner'
import AdminWrapper from '../../../layout/AdminWrapper.vue'
import { computed, onMounted, ref, watch,  } from '@vue/runtime-core'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import axios from 'axios'
export default {
    components: {
        AdminWrapper,
        Spinner,
    },
    setup() {
        const accountDetails = computed(() => store.state.Admin.accountDetails)
        const store = useStore()
        const route = useRouter()
        const orgName = ref('')
        const orgEmail = ref('')
        const orgPhoneNo = ref('')
        const orgType = ref('')
        const orgSector = ref('')
        const primaryOwnerName = ref('')
        const primaryOwnerEmail = ref('')
        const orgAddress = ref('')
        const orgCountry = ref('')
        const orgSource = ref('')
        const image = ref('')
        const countries = ref([])
        const sortedCountries = ref([])
        const selectedCountry = ref(null)

        const allCountries = async () => {
            try {
                const response = await axios.get('https://restcountries.com/v3.1/all')
                countries.value = response.data
            } catch (error) {
                console.error('Error fetching countries:', error)
            }
        }
        const sortCountriesAlphabetically = () => {
            sortedCountries.value = [...countries.value].sort((a, b) => a.name.common.localeCompare(b.name.common))
        }

        // Watch for changes in countries and sort when updated
        watch(countries, () => {
            sortCountriesAlphabetically()
        })

        const handleImageUpload = (e) => {
            image.value = e.target.files[0]
            // console.log(image.value, 'image')
        }

        const getDetails = () => {
            orgName.value = accountDetails.value.name
            orgEmail.value = accountDetails.value.orgEmail
            orgPhoneNo.value = accountDetails.value.phoneNumber
            orgType.value = accountDetails.value.typeOfBusiness
            orgSector.value = accountDetails.value.business
            primaryOwnerName.value = accountDetails.value.owner?.name
            primaryOwnerEmail.value = accountDetails.value.owner?.email
            orgAddress.value = accountDetails.value.address
            orgCountry.value = accountDetails.value.country
            orgSource.value = accountDetails.value.lead
        }

        const updateUserInfo = () => {
            let formData = new FormData()
            formData.append('name', orgName.value)
            formData.append('phoneNumber', orgPhoneNo.value)
            formData.append('orgEmail', orgEmail.value)
            formData.append('business', orgSector.value)
            formData.append('typeOfBusiness', orgType.value)
            formData.append('address', orgAddress.value)
            formData.append('country', orgCountry.value)
            formData.append('lead', orgSource.value)

            store.dispatch('Admin/updateBusiness', {
                id: route.currentRoute.value.params.id,
                payload: formData,
                // {
                //     name: orgName.value,
                //     phoneNumber: orgPhoneNo.value,
                //     orgEmail: orgEmail.value,
                //     business: orgSector.value,
                //     typeOfBusiness: orgType.value,
                //     address: orgAddress.value,
                //     country: orgCountry.value,
                //     lead: orgSource.value
                // }
            })
        }

        onMounted(() => allCountries())
        onMounted(() => {
            store.dispatch('Admin/getAccountBusinessDetails', route.currentRoute.value.params.id)

            getDetails()
        })

        return {
            accountDetails,
            orgName,
            orgEmail,
            orgPhoneNo,
            orgType,
            orgSector,
            getDetails,
            updateUserInfo,
            primaryOwnerName,
            primaryOwnerEmail,
            orgAddress,
            orgCountry,
            orgSource,
            handleImageUpload,
            image,
            sortedCountries,
            selectedCountry,
        }
    },
}
</script>

<style></style>
